<template>
    <div>
        <!-- 面包屑 -->
        <div class="breadcrumb">
            <el-breadcrumb>
                <el-breadcrumb-item :to="{ path: '/hoveContent' }">数控机床一站式服务平台首页</el-breadcrumb-item>
                <el-breadcrumb-item >发布需求信息</el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <!-- 主题内容 -->
        <div class="cont">
            <div class="title">发布需求信息</div>
            <div class="remind">
                <div class="text">1.成功发布询价信息，即表示您同意注册成为机床商务网采购商。后续您可通过手机验证码登录采购中心，管理询价信息。</div>
                <div class="text"> 2.您发布的信息将在一个工作日内审核，通过审核后即可在网站前台展示。 </div>
                <div class="text">3.此页面仅可发布产品询价信息，其他信息（如广告等）将一律删除。</div>
            </div>

            <!-- 产品类目对话框 -->
            <el-dialog title="产品类目" width="85%" :visible.sync="outerVisibleYW">
                <div class="dialogYW">
                    <div class="l">
                        <div class="son cur" @click="typeNameCLick(index)" v-for="(i, index) in outerVisibleYWList"
                            :key="index" :class="[i.isShow ? 'sonAct' : '']">{{ i.typeName }}</div>
                    </div>
                    <div class="r" v-if="outerVisibleYWList.length > 0">
                        <div class="topImg">
                            <img :src="outerVisibleYWList.find(obj => obj.isShow).typeImage" alt="">
                        </div>
                        <div class="content" v-for="(it, ind) in outerVisibleYWList.find(obj => obj.isShow).children"
                            :key="ind">
                            <div class="title">{{ it.typeName }}</div>
                            <div class="sonBox">
                                <div class="sonn" v-for="(mt, inde) in it.children" :key="inde" @click="mtClick(mt)">
                                    <div class="imgBox">
                                        <img class="img" :src="mt.typeImage" alt="">
                                        <img v-if="mt.isShow" class="imgXz" src="@/assets/xz.png" alt="">
                                    </div>
                                    <div class="name">{{ mt.typeName }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div slot="footer" class="dialog-footer">
                    <el-button @click="outerVisibleYW = false">取 消</el-button>
                    <el-button type="primary" @click="determineYWClick">确 定</el-button>
                </div>
            </el-dialog>

            <!-- 表格 -->
            <div class="form">
                <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px" class="demo-ruleForm">
                    <el-form-item label="产品名称：" prop="productName" style="width: 580px;">
                        <el-input v-model="ruleForm.productName" placeholder="请输入产品名称"></el-input>
                    </el-form-item>
                    <el-form-item label="产品类目：" prop="productCategory" style="width: 500px;">
                        <div @click="outerVisibleYW = true">
                            <el-input v-model="ruleForm.productCategory" placeholder="请选择主营业务" disabled
                                suffix-icon="el-icon-arrow-down"></el-input>
                        </div>
                    </el-form-item>
                    <el-form-item label="采购数量：" prop="purchaseQuantity" style="width: 300px;">
                        <el-input v-model="ruleForm.purchaseQuantity" placeholder="请输入采购数量" :type="'number'"
                            :min="1"></el-input>
                    </el-form-item>
                    <el-form-item label="计量单位：" prop="measuringUnit">
                        <el-select v-model="ruleForm.measuringUnit" placeholder="选择计量单位">
                            <el-option v-for="(i, index) in measurementTunit" :key="index" :label="i.value"
                                :value="i.value"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="单价上限：" prop="unitPriceCeiling" style="width: 300px;">
                        <el-input v-model="ruleForm.unitPriceCeiling" placeholder="请输入单价上限" :type="'number'"
                            :min="1"></el-input>
                    </el-form-item>
                    <el-form-item label="详情描述：" prop="detailsDescription">
                        <el-input type="textarea" v-model="ruleForm.detailsDescription" show-word-limit :rows="5"
                            :maxlength="200" placeholder="请详细描述寻源产品，如规格型、型号、材质等，可增加报价正确率"></el-input>
                    </el-form-item>
                    <el-form-item label="参考图片：" prop="referencePictures">
                        <el-upload action="https://api.cnc-x.cn//mall/uploads" list-type="picture-card"
                            :data="{ type: '4' }" :on-preview="handlePictureCardPreview" :on-remove="handleRemove"
                            :on-change="handleChange">
                            <i class="el-icon-plus"></i>
                        </el-upload>
                        <el-dialog :visible.sync="dialogVisible">
                            <img width="100%" :src="ruleForm.referencePictures" alt="">
                        </el-dialog>
                    </el-form-item>
                    <el-form-item label="报价截止时间：" prop="quotationDeadline">
                        <el-date-picker v-model="ruleForm.quotationDeadline" value-format="yyyy-MM-dd HH:mm:ss"
                            format="yyyy-MM-dd HH:mm:ss" type="datetime" placeholder="选择日期">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label="期望收货时间：" prop="hopeTheGoodsTime">
                        <el-date-picker v-model="ruleForm.hopeTheGoodsTime" value-format="yyyy-MM-dd HH:mm:ss"
                            format="yyyy-MM-dd HH:mm:ss" type="datetime" placeholder="选择日期">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label="发货方式：" prop="deliveryWay">
                        <el-select v-model="ruleForm.deliveryWay" placeholder="请选择发货方式">
                            <el-option v-for="(i, index) in resourcelist" :key="index" :label="i.label"
                                :value="i.label"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="送货地址：" prop="deliveryAddress" style="width: 580px;">
                        <el-cascader :options="options" v-model="ruleForm.deliveryAddress"
                            :props="{ value: 'label', label: 'label' }" style="margin-bottom: 10px;"></el-cascader>
                    </el-form-item>
                    <el-form-item label="" prop="deliveryAddress2" style="width: 580px;">
                        <el-input v-model="ruleForm.deliveryAddress2" placeholder="请输入详细地址"></el-input>
                    </el-form-item>
                    <el-form-item label="其他要求：">
                        <el-input type="textarea" v-model="ruleForm.requirements" show-word-limit :rows="5" :maxlength="200"
                            placeholder="请填写其他特殊要求"></el-input>
                    </el-form-item>
                    <div class="btn cur" @click="submitForm('ruleForm')">立即发布</div>
                </el-form>
            </div>
        </div>


    </div>
</template>

<script>
import { getMainBusiness } from "@/utils/api/myApi/index"
import { getMeasurementTunit,postSubmitSourcing } from "@/utils/api/homeApi/index"

export default {
    name: 'souurcingPublish',
    data() {
        return {
            dialogVisible: false,
            // 类目
            outerVisibleYW: false,
            // 类目列表
            outerVisibleYWList: [],
            // 计量单位
            measurementTunit: [],
            // 收获方式
            resourcelist: [{
                value: '1',
                label: '快递上门'
            },
            {
                value: '2',
                label: '快递上门-无需卸货'
            },
            {
                value: '3',
                label: '快递上门-包卸货'
            },
            {
                value: '4',
                label: '物流站点自提'
            },
            {
                value: '5',
                label: '买家自提'
            },
            ],
            ruleForm: {
                productName: '', // 产品名称
                productCategory: '', // 产品类目
                categroyId:'',  // 类目id
                purchaseQuantity: '', // 采购数量
                measuringUnit: '',   // 单位
                unitPriceCeiling: '',   // 单价上线
                detailsDescription: '', // 详情描述
                referencePictures: '',  // 参考图片
                quotationDeadline: '',  // 报价截止时间：
                hopeTheGoodsTime: '',  // 期望收获时间
                deliveryWay: '',    // 发货方式
                deliveryAddress: '',    // 送货地址
                deliveryAddress2: '',   // 详细地址
                requirements: ''    // 其他要求
            },
            // 地址
            addressData: [
                {
                    value: '110000',
                    label: '北京市',
                    children: [
                        {
                            value: '110100',
                            label: '北京市',
                            children: [
                                {
                                    value: '110101',
                                    label: '东城区'
                                },
                                {
                                    value: '110102',
                                    label: '西城区'
                                },
                                {
                                    value: '110105',
                                    label: '朝阳区'
                                },
                                // ... 其他区县
                            ]
                        }
                    ]
                },
                {
                    value: '120000',
                    label: '天津市',
                    children: [
                        {
                            value: '120100',
                            label: '天津市',
                            children: [
                                {
                                    value: '120101',
                                    label: '和平区'
                                },
                                {
                                    value: '120102',
                                    label: '河东区'
                                },
                                {
                                    value: '120105',
                                    label: '河北区'
                                },
                                // ... 其他区县
                            ]
                        }
                    ]
                },
                // ... 其他省份和城市
            ],
            options: [], // 转换后的结果
            // 校验
            rules: {
                productName: [
                    { required: true, message: '请输入产品名称', trigger: 'blur' }
                ],
                productCategory: [
                    { required: true, message: '请选择产品类目', trigger: 'change' }
                ],
                purchaseQuantity: [
                    { required: true, message: '请输入采购数量', trigger: 'blur' }
                ],
                measuringUnit: [
                    { required: true, message: '请选择计量单位', trigger: 'change' }
                ],
                unitPriceCeiling: [
                    { required: true, message: '请输入单价上限', trigger: 'blur' }
                ],
                detailsDescription: [
                    { required: true, message: '请详细描述寻源产品，如规格型、型号、材质等，可增加报价正确率', trigger: 'blur' }
                ],
                referencePictures: [
                    { required: true, message: '请上传参考图片', trigger: 'blur' }
                ],
                quotationDeadline: [
                    { required: true, message: '请输入报价截止时间', trigger: 'blur' }
                ],
                hopeTheGoodsTime: [
                    { required: true, message: '请输入期望收货时间', trigger: 'blur' }
                ],
                deliveryWay: [
                    { required: true, message: '请选择发货方式', trigger: 'change' }
                ],
                deliveryAddress: [
                    { required: true, message: '请选择送货地址', trigger: 'change' }
                ],
                deliveryAddress2: [
                    { required: true, message: '请填写详细地址', trigger: 'blur' }
                ],
            }
        }
    },
    mounted() {
        this.options = this.$addresses
        // 获取类目数据
        this.outerVisibleYWClick()
        // 获取计量单位
        this.getTunit()
    },
    methods: {
        handleRemove(file, fileList) {
            console.log(file, fileList);
        },
        handlePictureCardPreview(file) {
            this.ruleForm.referencePictures = file.url;
            this.dialogVisible = true;
        },
        handleChange(file, fileList) {
            this.ruleForm.referencePictures = fileList;
            this.$refs.ruleForm.validateField('referencePictures');
        },
        // 获取类目列表数据
        async outerVisibleYWClick() {
            const res = await getMainBusiness()
            this.outerVisibleYWList = res.data
            this.outerVisibleYWList.forEach(obj => {
                obj.isShow = false
            });
            this.outerVisibleYWList.map(f => f.children.map(h => h.children.map(d => {
                d.isShow = false
            })))
            this.outerVisibleYWList[0].isShow = true
        },
        // 获取计量单位
        async getTunit() {
            const res = await getMeasurementTunit('计量单位')
            if (res.statusCode == 8201) {
                this.measurementTunit = res.data[0].dictionaryVoList
                console.log(this.measurementTunit)
            }
        },
        // 左侧菜单选择事件
        typeNameCLick(index) {
            this.outerVisibleYWList.forEach(obj => {
                obj.isShow = false
            });
            this.outerVisibleYWList[index].isShow = true
        },
        // 选择业务事件
        mtClick(mt) {
            this.outerVisibleYWList.map(f => f.children.map(h => h.children.map(d => {
                d.isShow = false
                if (d.id == mt.id) {
                    d.isShow = true
                    return
                }
            })))
        },
        // 业务选择确定
        determineYWClick() {
            this.outerVisibleYWList.map(f => f.children.map(h => h.children.map(d => {
                if (d.isShow) {
                    this.ruleForm.productCategory = d.typeName
                    this.ruleForm.categroyId = d.id
                    return
                }
            })))
            this.outerVisibleYW = false
        },
        // 提交按钮
        submitForm(formName) {
            this.$refs[formName].validate(async (valid) => {
                if (valid) {
                     this.ruleForm.referencePictures = this.ruleForm.referencePictures.map(d => d.response.data).join(',')
                    let measureUnitId = ''
                    this.measurementTunit.map(d => {
                        if( d.value == this.ruleForm.measuringUnit) {
                            measureUnitId = d.ddId
                        }
                    })
                    let deliveryTypeId = ''
                    this.resourcelist.map(b => {
                        if(this.ruleForm.deliveryWay == b.label) {
                            deliveryTypeId = b.value
                        }
                    })

                    let data = {
                        productName:this.ruleForm.productName,
                        categroyName:this.ruleForm.productCategory,
                        categroyId:this.ruleForm.categroyId,
                        purchaseNum:this.ruleForm.purchaseQuantity,
                        measureUnit:this.ruleForm.measuringUnit,
                        measureUnitId:measureUnitId,
                        ceilingPrice:this.ruleForm.unitPriceCeiling,
                        description:this.ruleForm.detailsDescription,
                        demandImgs:this.ruleForm.referencePictures,
                        gmtDeadline:this.ruleForm.quotationDeadline,
                        gmtReceiveExpect:this.ruleForm.hopeTheGoodsTime,
                        deliveryType:this.ruleForm.deliveryWay,
                        deliveryTypeId:deliveryTypeId,
                        deliveryProvince:this.ruleForm.deliveryAddress[0],
                        deliveryCity:this.ruleForm.deliveryAddress[1],
                        deliveryCounty:this.ruleForm.deliveryAddress[2],
                        deliveryAddress:this.ruleForm.deliveryAddress2,
                        address:`${this.ruleForm.deliveryAddress[0]}${this.ruleForm.deliveryAddress[1]}${this.ruleForm.deliveryAddress[2]}${this.ruleForm.deliveryAddress2}`,
                        requirement:this.ruleForm.requirements,
                        type:'add'
                    }
                    console.log(data)
                    const res = await postSubmitSourcing(data)
                    if(res.data) {
                        this.$message.success('提交成功');
                        this.$router.push('/myIndex/mySourcing?actMyNum=3')
                    }else {
                        this.$message.error('提交失败，请稍后再试');
                    }
                } else {
                    this.$message.warning('请填写完整信息');
                    return false;
                }
            });
        },
    }
}
</script>

<style lang="less" scoped>
/deep/ .el-upload-list--picture-card .el-upload-list__item-thumbnail {
    width: 100%;
    object-fit: cover !important;
}

/deep/ .el-input.is-disabled .el-input__inner {
    cursor: pointer !important;
}

/deep/ .el-input.is-disabled .el-input__icon {
    cursor: pointer !important;
}

/deep/ .el-dialog {
    background-color: #f4f4f4;
    margin-top: 50px !important;
}

/deep/ .el-dialog__body {
    padding: 10px 5px !important;
}

.breadcrumb {
    width: 1200px;
    margin: 0px auto;
    padding: 20px;
    box-sizing: border-box;
}

// 主题内容
.cont {
    width: 1200px;
    height: auto;
    background-color: #fff;
    padding: 17px 32px;
    box-sizing: border-box;
    margin: 20px auto;

    >.title {
        font-size: 24px;
        color: #000000;
    }

    >.remind {
        width: 100%;
        height: 115px;
        padding: 15px 20px;
        box-sizing: border-box;
        font-size: 14px;
        color: #333;
        background-color: #FFFAE8;
        border: 1px solid #F5E3B4;
        margin-top: 20px;

        >div {
            margin-bottom: 10px;
        }
    }

    >.form {
        width: 900px;
        height: auto;
        margin-top: 50px;
        margin-left: 130px;
    }

    .btn {
        width: 180px;
        height: 60px;
        border-radius: 8px;
        background-color: #FF4242;
        font-size: 24px;
        color: #fff;
        text-align: center;
        line-height: 60px;
        margin-left: 120px;
    }
}

.dialogYW {
    width: 100%;
    height: 70vh;
    display: flex;
    justify-content: space-between;

    >.l {
        width: 200px;
        height: 100%;
        overflow-y: auto;

        >.son {
            font-size: 14px;
            color: #555;
            width: 100%;
            text-align: center;
            height: 50px;
            line-height: 50px;
        }

        >.sonAct {
            color: #e1241b;
            font-weight: bold;
        }
    }

    >.r {
        width: calc(100% - 220px);
        height: 100%;
        padding: 0px 10px;
        box-sizing: border-box;
        overflow-y: auto;

        >.topImg {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 20px;

            >img {
                height: 100px;
                object-fit: contain;
            }
        }

        >.content {
            width: 100%;
            height: auto;
            padding: 10px;
            box-sizing: border-box;
            background-color: #fff;
            border-radius: 10px;
            margin-bottom: 20px;

            >.title {
                font-weight: bold;
            }

            >.sonBox {
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                // justify-content: space-around;

                >.sonn {
                    width: 30%;
                    display: flex;
                    margin-bottom: 15px;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    margin-right: 3%;

                    >.imgBox {
                        width: 60px;
                        height: 60px;
                        position: relative;

                        >.img {
                            height: 54px;
                            width: 54px;
                            object-fit: cover;
                        }

                        >.imgXz {
                            width: 24px;
                            position: absolute;
                            bottom: 0;
                            right: 0;
                            object-fit: contain;
                        }
                    }


                    >.name {
                        width: 120px;
                        text-align: center;
                        color: #333;
                    }
                }
            }
        }
    }
}

/deep/ .el-breadcrumb__inner.is-link:hover {
    color: #ff4242 !important;
}

/* 整个滚动条 */
::-webkit-scrollbar {
    /* 对应纵向滚动条的宽度 */
    width: 5px;
    /* 对应横向滚动条的宽度 */
    height: 5px;
}

/* 滚动条上的滚动滑块 */
::-webkit-scrollbar-thumb {
    background-color: #b3b6bb8f;
    border-radius: 32px;
}

/* 滚动条轨道 */
::-webkit-scrollbar-track {
    // background-color: #d3d3d386;
    background-color: #f4f4f4;
    border-radius: 32px;
}
</style>